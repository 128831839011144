import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ma-10"
  }, [_c(VRow, [_c('h1', {
    staticClass: "text-h4 font-weight-bold primary--text ml-3"
  }, [_vm._v(" Neue Aufgabe ")])]), _c(VRow, {
    staticClass: "mt-4",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center text-overline font-weight-bold mt-3"
  }, [_c(VIcon, {
    staticClass: "mr-2 mb-1"
  }, [_vm._v("mdi-text")]), _vm._v("Titel ")], 1), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c(VCombobox, {
    attrs: {
      "outlined": "",
      "dense": "",
      "items": _vm.titles,
      "item-text": "title",
      "item-value": item => item
    },
    model: {
      value: _vm.task.title,
      callback: function ($$v) {
        _vm.$set(_vm.task, "title", $$v);
      },
      expression: "task.title"
    }
  })], 1)], 1), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "text-overline font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mr-2 mb-1"
  }, [_vm._v("mdi-text-long")]), _vm._v("Beschreibung "), _c(VDivider)], 1)]), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-start"
  }, [_c('RichTextEditor', {
    ref: "richTextDescription",
    attrs: {
      "text": _vm.task.description,
      "placeholder": "Hier Text eingeben",
      "editable": ""
    }
  })], 1)])], 1), _c(VRow, {
    staticClass: "mt-7",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    staticClass: "mt-7 mt-xl-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "11",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center text-overline font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-calendar")]), _vm._v("Fälligkeitsdatum ")], 1), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c(VMenu, {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date,
      "transition": "scale-transition",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date = $event;
      },
      "update:return-value": function ($event) {
        _vm.date = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          attrs: {
            "readonly": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.dueDateFormatted,
            callback: function ($$v) {
              _vm.dueDateFormatted = $$v;
            },
            expression: "dueDateFormatted"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.dueDateMenu,
      callback: function ($$v) {
        _vm.dueDateMenu = $$v;
      },
      expression: "dueDateMenu"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "color": "primary",
      "no-title": "",
      "scrollable": ""
    },
    model: {
      value: _vm.task.dueDate,
      callback: function ($$v) {
        _vm.$set(_vm.task, "dueDate", $$v);
      },
      expression: "task.dueDate"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.menu = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu.save(_vm.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1)], 1)], 1), _c(VCol, {
    staticClass: "mt-7 mt-xl-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center text-overline font-weight-bold"
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-list-status")]), _vm._v("Status ")], 1), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "10",
      "xl": "8"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.taskStates,
      "item-text": "description",
      "item-value": item => item,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.task.status,
      callback: function ($$v) {
        _vm.$set(_vm.task, "status", $$v);
      },
      expression: "task.status"
    }
  })], 1)], 1)], 1)], 1), _c(VRow, {
    staticClass: "mt-7",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', [_c(VIcon, [_vm._v("mdi-clipboard-account")])], 1), _c('div', {
    staticClass: "ml-2 text-overline font-weight-bold"
  }, [_vm._v(" Bearbeiter ")]), _c('div', {
    staticClass: "ml-2"
  }, [_c(VBtn, {
    staticClass: "mt-n1 rounded-sm",
    attrs: {
      "icon": "",
      "x-small": "",
      "outlined": "",
      "tile": ""
    },
    on: {
      "click": _vm.openTaskAssigned
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)]), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [_vm._l(_vm.task.assignedUsers, function (usr) {
    return _c(VListItem, {
      key: usr.userId
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
  }), _vm._l(_vm.task.assignedWorkGroups, function (grp) {
    return _c(VListGroup, {
      key: grp.code,
      attrs: {
        "prepend-icon": "mdi-account-group"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(grp.name))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(grp.users, function (usr) {
      return _c(VListItem, {
        key: usr.userId
      }, [_c(VListItemIcon, [_c(VIcon, {
        attrs: {
          "small": "",
          "right": ""
        }
      }, [_vm._v("mdi-account-tie")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(usr.username))]), _c(VListItemSubtitle, [_vm._v(_vm._s(usr.userId))])], 1)], 1);
    }), 1);
  })], 2)], 1)], 1)], 1), _c(VCol, {
    staticClass: "mt-7 mt-xl-0",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', [_c(VIcon, [_vm._v("mdi-package-variant-closed")])], 1), _c('div', {
    staticClass: "ml-2 text-overline font-weight-bold"
  }, [_vm._v(" Verlinkte Elemente ")]), _c('div', {
    staticClass: "ml-2"
  }, [_c(VBtn, {
    staticClass: "mt-n1 rounded-sm",
    attrs: {
      "icon": "",
      "x-small": "",
      "outlined": "",
      "tile": ""
    },
    on: {
      "click": _vm.openTaskObjects
    }
  }, [_c(VIcon, {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)]), _c(VDivider)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xl": "12"
    }
  }, [_c(VList, {
    attrs: {
      "dense": "",
      "shaped": ""
    }
  }, [_vm._l(_vm.objects.addressesWithOutDocuments, function (address) {
    return _c(VListItem, {
      key: address.wfCid
    }, [_c(VListItemIcon, [_c(VIcon, [_vm._v("mdi-account")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(address.displayName))])], 1)], 1);
  }), _vm._l(_vm.objects.addresses, function (address) {
    return _c(VListGroup, {
      key: address.wfCid,
      attrs: {
        "prepend-icon": "mdi-account"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c(VListItemTitle, [_vm._v(_vm._s(address.displayName))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(address.documents, function (document) {
      return _c(VListItem, {
        key: document.wfcid,
        attrs: {
          "dense": ""
        }
      }, [_c(VListItemIcon, [_c(VIcon, {
        attrs: {
          "small": "",
          "right": ""
        }
      }, [_vm._v("mdi-file-document")])], 1), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(document.displayName))])], 1)], 1);
    }), 1);
  })], 2)], 1)], 1)], 1)], 1), _c(VFooter, {
    staticClass: "ml-3",
    attrs: {
      "absolute": "",
      "color": "white"
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": _vm.create
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-list-box")]), _vm._v("Aufgabe erstellen")], 1)], 1), _c('TaskAssigned', {
    ref: "assigned",
    on: {
      "saved": _vm.savedAssigned
    }
  }), _c('DatePicker', {
    ref: "datePicker",
    on: {
      "datePicked": _vm.datePicked
    }
  }), _c('TaskObjects', {
    ref: "objects",
    on: {
      "save": _vm.saveObjects
    }
  }), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };