import "core-js/modules/es.array.push.js";
import TaskAssigned from '@/components/task/TaskAssigned.vue';
import { TaskClass } from '@/models/task/Task.js';
import DatePicker from '../../components/task/DatePicker.vue';
import dateTimeService from '@/services/cal/dateTimeService.js';
import TaskObjects from '../../components/task/TaskObjects.vue';
import RichTextEditor from '@/components/core/RichTextEditor.vue';
import { mapState } from 'vuex';
import axios from 'axios';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
export default {
  data: () => ({
    task: new TaskClass(),
    dateTimeService,
    errorDialog: false,
    failMessage: '',
    objectAddresses: [],
    objectDocuments: [],
    objects: {
      addresses: [],
      addressesWithOutDocuments: []
    },
    dueDateMenu: null,
    date: null
  }),
  components: {
    TaskAssigned,
    DatePicker,
    TaskObjects,
    RichTextEditor,
    ErrorDialog
  },
  mounted() {
    this.$store.dispatch('loadTitles');
    this.$store.dispatch('loadTaskStates').then(() => {
      this.task.status = this.taskStates.find(state => state.key.lfdnr == 10);
    });
  },
  computed: {
    ...mapState({
      titles: state => state.task.titles,
      taskStates: state => state.task.taskStates
    }),
    dueDateFormatted() {
      return this.task.dueDate ? dateTimeService.getGermanDateFormatWithoutTime(this.task.dueDate) : '';
    }
  },
  methods: {
    openTaskAssigned() {
      this.$refs.assigned.open(this.task.assignedUsers, this.task.assignedWorkGroups, 'edit');
    },
    openTaskObjects() {
      this.$refs.objects.open(this.objectAddresses.concat(this.objectDocuments));
    },
    savedAssigned(users, groups) {
      this.task.assignedUsers = users;
      this.task.assignedWorkGroups = groups;
    },
    saveObjects(addresses, documents) {
      this.objectAddresses = addresses;
      this.objectDocuments = documents;
      this.getObjects(addresses.concat(documents));
    },
    getObjects(objects) {
      this.objects = {
        addresses: [],
        addressesWithOutDocuments: []
      };
      let addresses = [];
      let documents = [];
      objects.forEach(element => {
        if (element.type === 'ADDRESS') {
          addresses.push(element);
        } else if (element.type === 'DOCUMENT') {
          documents.push(element);
        }
      });
      addresses.forEach(address => {
        let tempObject = {
          lfdnr: address.lfdnr,
          taskId: address.taskId,
          wfCid: address.wfCid,
          type: address.type,
          displayName: address.displayName,
          parentWfCid: address.parentWfCid,
          documents: []
        };
        documents.forEach((document, index) => {
          if (document.parentWfCid === address.wfCid) {
            tempObject.documents.push(document);
            documents.splice(index, 1);
          }
        });
        if (tempObject.documents.length != 0) {
          this.objects.addresses.push(tempObject);
        } else {
          this.objects.addressesWithOutDocuments.push(tempObject);
        }
      });
    },
    openDatePicker() {
      this.$refs.datePicker.open();
    },
    datePicked(date) {
      this.task.dueDate = date;
    },
    create() {
      if (this.task.title !== '' && this.task.dueDate !== '') {
        this.task.description = this.$refs.richTextDescription.getHTML();
        var temp = this.task.dueDate + 'T00:00:00';
        this.task.dueDate = temp;
        console.warn(this.task);
        console.warn(this.objectAddresses);
        console.warn(this.objectDocuments);
        var documentObjects = this.objectDocuments;
        var addressObjects = this.objectAddresses;
        documentObjects = documentObjects.map(object => {
          return {
            wfCid: object.wfCid,
            taskId: this.task.lfdnr
          };
        });
        axios.post('v1/task-resources/base/addresses', addressObjects.map(object => object.additionalData.lfdnr)).then(response => {
          addressObjects = JSON.parse(JSON.stringify(response.data));
          addressObjects = response.data.map(object => {
            return {
              wfCid: object.wfCid,
              taskId: this.task.lfdnr
            };
          });
          console.log(documentObjects);
          console.log(addressObjects);
          const patchableObjects = documentObjects.concat(addressObjects);
          this.task.objects = patchableObjects;
          axios.put('/v1/task', this.task);
          this.$router.push('/task');
          this.$toast.success('Aufgabe wurde erstellt');
        });
      } else {
        if (this.task.title === '' && this.task.dueDate === '') this.failMessage = 'Bitte Titel und Fälligkeitsdatum eingeben!';else if (this.task.title === '' && this.task.dueDate !== '') this.failMessage = 'Bitte Titel eingeben!';else if (this.task.title !== '' && this.task.dueDate === '') this.failMessage = 'Bitte Fälligkeitsdatum eingeben!';
        this.errorDialog = true;
      }
    }
  }
};